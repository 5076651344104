import React from "react";
import Seo from "../components/seo/index";
import Layout from "../layout/index";
import FailedScreen from "../components/sharedComponents/failedScreen";

export default function Failed() {
  return (
    <>
      <div>
        <Seo title="Green Heartfulness Run 2024 - Payment Failed" />
        <Layout isSticky>
          <section>
            <FailedScreen />
          </section>
        </Layout>
      </div>
    </>
  );
}
